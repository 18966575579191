.col_4 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.clientlogocard {
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 6rem;
}
.mobileview {
  display: flex;
  justify-content: center;
}
.bigImgWrapper {
  display: none;
}
@media (min-width: 992px) {
  .bigImgWrapper {
    display: flex;
    justify-content: center;
    align-content: flex-start;
  }
  .bigImgWrapper img {
    width: 65vw;
  }
  .mobileview {
    display: none;
  }
  .clientlogocard {
    padding-bottom: 6rem;
  }
}

@media (min-width: 1600px) {
  .bigImgWrapper img {
    width: 70vw;
  }
}
