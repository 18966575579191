@font-face {
  font-family: montBold;
  src: url(../../fonts/Montserrat/static/Montserrat-Bold.ttf);
}
.contactpage {
  position: relative;
  padding-bottom: 4rem;
  scroll-margin-top: 100px;
}
.heading_input_conatiner {
  background-color: #001481;
  margin: 2rem 2rem 3rem 2rem;
  border-radius: 20px;
  padding: 1.5rem 1.5rem 1rem 1.5rem;
}
.heading {
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.heading h1 {
  font-family: montBold;
  /*   font-size: 1.3rem; */
  font-size: 5.2vw;
  width: 70vw;
  /*   margin-left: 0.4rem; */
  line-height: 23px;
}
.inputContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.inputwrapper {
  width: 70vw;
}
.inputfield {
  cursor: pointer;
  word-spacing: 2px;
  outline: none !important;
  outline-offset: none !important;
  border: none !important;
  text-align: center;
  font-size: 0.9rem;
  background-color: #f5f5f5;
  color: #a6aed8;
  font-family: Poppins, Regular;
  height: 2.2rem;
}
.inputfield::placeholder {
  color: #a6aed8;
  opacity: 1;
}

.inputfield:focus {
  outline: 0px none #fff !important;
}
.inputContainer button {
  cursor: pointer;
  height: 2.2rem;
  background-color: #0339d9;
  border: none;
}
.mail {
  transition: all 0.6s;
  background-color: #0339d9;
  color: #ffffff;
  font-size: 1.4rem;
}
@media (min-width: 576px) {
  .heading_input_conatiner {
    padding: 2rem 1.5rem 1.5rem 1.5rem;
  }
  .heading h1 {
    line-height: 35px;
    margin-left: 0.8rem;
  }
}
@media (min-width: 768px) {
  .heading_input_conatiner {
    margin: 2rem 3rem 3rem 3rem;
    padding: 3.5rem 1.5rem 3rem 1.5rem;
  }
  .heading h1 {
    font-size: 5.3vw;
    width: 70vw;
    padding-left: 1rem;
    line-height: 43px;
  }
  .inputwrapper {
    width: 65vw;
  }
  .inputfield {
    margin-top: 5px;
    word-spacing: 2px;
    font-size: 1.5rem;
    height: 3.3rem;
  }
  .inputContainer button {
    margin-top: 5px;
    height: 3.3rem;
  }
  .mail {
    font-size: 2rem;
  }
}
@media (min-width: 992px) {
  .contactpage {
    padding-top: 2rem;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: row;
    padding-left: 0rem;
    padding-right: 0rem;
    padding-bottom: 8rem;
  }
  .heading_input_conatiner {
    margin: 0rem;
    padding: 0rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: transparent;
  }

  .heading h1 {
    font-size: 1.8vw;
    width: 27vw;
    line-height: 25px;
  }
  .inputContainer {
    width: 25vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .inputwrapper {
    width: 23vw;
  }
  .inputfield {
    margin-top: 5px;
    word-spacing: 0px;
    font-size: 1vw;
    height: 3rem;
    width: 18vw;
  }
  .inputContainer button {
    margin-top: 5px;
    height: 3rem;
  }
}
@media (min-width: 1280px) {
  .contactpage {
    justify-content: space-evenly;
  }
  .heading h1 {
    width: 26vw;
  }
}
@media (min-width: 1800px) {
  .contactpage {
    justify-content: center;
  }
  .heading h1 {
    width: 25vw;
  }
}
@media (min-width: 1920px) {
  .heading h1 {
    line-height: 40px;
  }
  .inputfield {
    height: 3.3rem;
  }
  .inputContainer button {
    height: 3.3rem;
  }
}
@media (min-width: 2559px) {
  .heading h1 {
    line-height: 50px;
  }
}

/* bg img */
.bgimg {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -999;
}
